import React from "react"
import classNames from "classnames"
import * as classes from "./style.module.scss"
import WidgetGroup from "../widgets-group"

interface IStudentsCollapse {
  region: Array<any>
}

const StudentsCollapse: React.FC<IStudentsCollapse> = props => {
  const [isExpanded, toggleMore] = React.useState<boolean>(false)

  const handleClick = () => {
    toggleMore(!isExpanded)
  }
  return (
    <section>
      <div className={classNames("section-intro", classes.sectionContainer)}>
        <div className="container">
          <div className={classes.iconAndHeadingContainer}>
            <h3 className={classes.heading}>See our different teams and programs</h3>
            <button
              className={classNames(classes.expandButton, {
                [classes.expanded]: isExpanded,
              })}
              onClick={handleClick}
              type="button"
            >
              <svg
                className={classes.toggleButtonIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="4"
                viewBox="0 0 8 4"
              >
                <path d="M8.242 0L4 4.243-.242 0" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isExpanded && props.region && <WidgetGroup region={props.region} className="widget-students" />}
    </section>
  )
}

export default StudentsCollapse
