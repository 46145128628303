import React from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import "../layouts/Primary/styles/skin/templates/_location.scss"
import Widget from "../components/widgets"
import LatestJobs from "../components/LatestJobs"
import QuoteBanner from "../components/quote-banner"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"
import { isInBrowser } from "../utils/helpers"

import OgImage from "../images/og-image-sng.jpg"
// students and graduates components
import { SnGHearFromOurTeam, SnGBlueSection, SnGBanner, SnGHero } from "../components/students-graduates"
import StudentsCollapse from "../components/widgets/students-collapse"

export interface ITeamDetail {
  title: string
  slug: string
  seoTitle: string
  seoDescription: string
  ogImage?: {
    file?: {
      url: string
    }
  }

  bannerLogo: {
    fluid: any
  }
  bannerBackgroundColor: string

  heroTagline: string
  heroHeading: string
  heroContent: any
  heroButton: any
  heroImage: {
    fluid: any
  }

  hearFromOurTeamSectionHeading: string
  hearFromOurTeamSectionContent: any
  hearFromOurTeamSectionBackgroundColor: string

  blueSectionTitle: string
  blueSectionHeading: string
  blueSectionContent: { raw: any }
  blueSectionLogo: {
    fixed: any
  }
  blueSectionImage: {
    fluid: any
  }

  latestRolesHeading: string
  latestRolesDescription: any
  noLatestRolesDescription: string
  noLatestRolesButton: any

  quoteAuthor: string
  quoteContent: any
  quoteImage: {
    fluid: any
  }

  regionAboveJobs: Array<any>
  bottomRegion: Array<any>
}

export interface ITeamDetailProps {
  contentfulCareersStudentsGraduates: ITeamDetail
  allLever: {
    nodes: Array<any>
  }
}

interface ITeamDetailContext {
  id: string
  slug: string
  locale: string
  teams: Array<string>
}

const TeamDetail: React.FC<PageProps<ITeamDetailProps, ITeamDetailContext>> = ({ pageContext, path, data }) => {
  const locale = pageContext.locale || "en-US"
  let metaImage = data.contentfulCareersStudentsGraduates?.ogImage?.file?.url
  if (metaImage) {
    metaImage = metaImage.startsWith("//") ? `https:${metaImage}` : metaImage
  }
  const seoData = {
    title: data.contentfulCareersStudentsGraduates.seoTitle || data.contentfulCareersStudentsGraduates.title,
    description: data.contentfulCareersStudentsGraduates.seoDescription,
    image: metaImage,
  }

  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      <Layout seoData={seoData}>
        <SnGBanner
          logo={data?.contentfulCareersStudentsGraduates?.bannerLogo}
          backgroundColor={data?.contentfulCareersStudentsGraduates?.bannerBackgroundColor}
        />
        <SnGHero
          tagline={data.contentfulCareersStudentsGraduates.heroTagline}
          heading={data.contentfulCareersStudentsGraduates.heroHeading}
          content={data.contentfulCareersStudentsGraduates.heroContent}
          image={data.contentfulCareersStudentsGraduates.heroImage}
          heroButton={data.contentfulCareersStudentsGraduates.heroButton}
        />
        <SnGBlueSection
          title={data?.contentfulCareersStudentsGraduates?.blueSectionTitle}
          heading={data?.contentfulCareersStudentsGraduates?.blueSectionHeading}
          content={data?.contentfulCareersStudentsGraduates?.blueSectionContent}
          logo={data?.contentfulCareersStudentsGraduates?.blueSectionLogo}
          image={data?.contentfulCareersStudentsGraduates?.blueSectionImage}
        />
        <SnGHearFromOurTeam
          heading={data.contentfulCareersStudentsGraduates.hearFromOurTeamSectionHeading}
          content={data.contentfulCareersStudentsGraduates.hearFromOurTeamSectionContent}
          backgroundColor={data.contentfulCareersStudentsGraduates.hearFromOurTeamSectionBackgroundColor}
        />
        <StudentsCollapse region={data.contentfulCareersStudentsGraduates.regionAboveJobs} />
        <LatestJobs
          heading={data.contentfulCareersStudentsGraduates.latestRolesHeading}
          content={data.contentfulCareersStudentsGraduates.latestRolesDescription}
          jobs={data.allLever.nodes}
          contentNoJobs={data.contentfulCareersStudentsGraduates.noLatestRolesDescription}
          buttonNoJobs={data.contentfulCareersStudentsGraduates.noLatestRolesButton}
          isTeamPage={true}
          mainHeading={data.contentfulCareersStudentsGraduates.heroHeading}
          withPagination
        />
        <QuoteBanner
          quoteContent={data.contentfulCareersStudentsGraduates.quoteContent}
          quoteAuthor={data.contentfulCareersStudentsGraduates.quoteAuthor}
          quoteImage={data.contentfulCareersStudentsGraduates.quoteImage}
          primary
          className="studentsWave"
        />

        {data.contentfulCareersStudentsGraduates.bottomRegion &&
          data.contentfulCareersStudentsGraduates.bottomRegion.map(widgetData => (
            <Widget {...widgetData} key={widgetData.title} />
          ))}
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default TeamDetail

export const query = graphql`
  query ($id: String!, $teams: [String!]!) {
    allLever(filter: { categories: { team: { in: $teams } } }, sort: { fields: createdAt, order: DESC }) {
      nodes {
        ...JobPosting
      }
    }
    contentfulCareersStudentsGraduates(id: { eq: $id }) {
      id
      title
      slug
      seoTitle
      seoDescription
      ogImage {
        file {
          url
        }
      }
      bannerLogo {
        fluid: gatsbyImageData(width: 352, height: 127, quality: 100, formats: [AUTO, WEBP])
      }
      bannerBackgroundColor
      heroTagline
      heroHeading
      heroButton {
        ...ContentfulLink
      }
      heroContent {
        ...RichTextField
      }
      heroImage {
        fluid: gatsbyImageData(width: 696, height: 632, quality: 100, formats: [AUTO, WEBP])
        title
      }

      blueSectionTitle
      blueSectionHeading
      blueSectionLogo {
        fixed: gatsbyImageData(layout: FIXED, width: 125, height: 125, quality: 100, formats: [AUTO, WEBP])
      }
      blueSectionImage {
        fluid: gatsbyImageData(width: 700, height: 800, quality: 100, formats: [AUTO, WEBP])
      }
      blueSectionContent {
        ...RichTextField
      }

      hearFromOurTeamSectionHeading
      hearFromOurTeamSectionContent {
        ...RichTextField
      }
      hearFromOurTeamSectionBackgroundColor

      regionAboveJobs {
        ...ComponentCTA
      }

      latestRolesHeading
      latestRolesDescription {
        ...RichTextField
      }
      noLatestRolesDescription
      noLatestRolesButton {
        ...ContentfulLink
      }

      quoteContent {
        ...RichTextField
      }
      quoteAuthor
      quoteImage {
        fluid: gatsbyImageData(width: 737, height: 814, quality: 100, formats: [AUTO, WEBP])
        title
      }

      bottomRegion {
        ...ComponentCTA
      }
    }
  }
`
