import React, { useState } from "react"
import RichTextRenderer from "../../elements/RichTextRenderer"
import PlaySVG from "../SVGs/play.inline.svg"
import ModalVideo from "../../elements/ModalVideo"
import * as classes from "./index.module.scss"
// import styles from team->detail->style.scss as we use them inside SnGHearFromOurTeam component
import "../team/detail/style.scss"

import classNames from "classnames"

interface ISnGHearFromOurTeam {
  heading: string
  content: any
  backgroundColor: string
}

interface ISnGBlueSection {
  title: string
  heading: string
  content: { raw: any }
  logo: { fixed: any }
  image: { fluid: any; title?: string }
}

interface ISnGBanner {
  logo: { fluid: any; title?: string }
  backgroundColor: string
}

interface ISnGHero {
  tagline?: string
  heading: string
  content: { raw: any }
  image: { fluid: any; title?: string }
  heroButton: any
}

export const SnGHearFromOurTeam: React.FC<ISnGHearFromOurTeam> = ({ heading, content, backgroundColor }) => {
  return (
    <div className="blue-content" style={{ backgroundColor: backgroundColor ? backgroundColor : "#5ec59a" }}>
      <div className={classNames("container text-center", classes.containerLimiter)}>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <h2 className={classes.hearFromOurTeamHeading}>{heading}</h2>
          </div>
          <div className="col-lg-10 col-xl-8">{content && <RichTextRenderer json={content} />}</div>
        </div>
      </div>
    </div>
  )
}

export const SnGBlueSection: React.FC<ISnGBlueSection> = ({ title, heading, content, logo, image }) => {
  return (
    <section className={classes.blueSectionSection}>
      <div className={classNames(classes.blueSectionContainer, classes.containerLimiter)}>
        {title && <p className={classes.blueSectionTitle}>{title}</p>}
        {logo && (
          <div className={classes.blueSectionLogoContainer}>
            <img src={logo?.fixed?.images?.fallback?.src} alt={title} />
          </div>
        )}
        <div className={classes.blueSectionContentAndImageContainer}>
          <div className={classes.blueSectionImageContainer}>
            {image && (
              <img
                src={image?.fluid?.images?.fallback?.src}
                alt={image.title || ""}
                className={classNames("img-fluid", classes.blueSectionImage)}
              />
            )}
          </div>
          <div className={classes.blueSectionContentContainer}>
            {heading && <p className={classes.blueContentHeading}>{heading}</p>}
            {content && <RichTextRenderer json={content} className={classes.blueContentContent} />}
          </div>
        </div>
      </div>
    </section>
  )
}

export const SnGBanner: React.FC<ISnGBanner> = ({ logo, backgroundColor }) => {
  return logo ? (
    <section style={{ width: "100%", backgroundColor: backgroundColor ? backgroundColor : "##08739d" }} className={classes.logoSection}>
      <div
        className={classNames(classes.bannerContainer, classes.containerLimiter)}
        style={{ backgroundColor: backgroundColor ? backgroundColor : "##08739d" }}
      >
        {logo && (
          <div className={classes.bannerLogoContainer}>
            {/* <GuidewireImage fluid={logo?.fluid} className={classes.bannerLogo} /> */}
            <img
              src={logo?.fluid?.images?.fallback?.src}
              alt={logo?.title}
              className={classNames("img-fluid", classes.bannerLogo)}
            />
          </div>
        )}
      </div>
    </section>
  ) : null
}

export const SnGHero: React.FC<ISnGHero> = ({ tagline, heading, content, image, heroButton }) => {
  const [modalShow, setModalShow] = useState(false)
  const openModal = (e: any) => {
    e.preventDefault()
    setModalShow(true)
  }

  const hideModal = () => {
    setModalShow(false)
  }

  return (
    <section className={classes.heroSection}>
      <div className={classNames(classes.heroContainer, classes.containerLimiter)}>
        <div className={classes.heroContentContainer}>
          {tagline && <p className="h4">{tagline}</p>}
          {heading ? (
            typeof heading === "string" ? (
              <h1 className={classNames("hero-heading", classes.heroHeading)}>{heading}</h1>
            ) : (
              <RichTextRenderer json={heading} noContainer />
            )
          ) : null}
          {content && <RichTextRenderer json={content} className={classes.heroContent} />}
        </div>
        <div className={classes.heroLinkContainer}>
          {heroButton && heroButton?.url ? (
            <>
              {/* <a className={classNames("js-video", classes.heroLink)} href="#" onClick={openModal}> */}
                <img
                  src={image?.fluid?.images?.fallback?.src}
                  alt={image.title || ""}
                  className={classNames("img-fluid", classes.heroImage)}
                />
                {/* <span className={classNames("play-icon", classes.heroPlayButton)}>
                  <PlaySVG className="icon-sprite" />
                  <div className="sr-only">{heroButton?.label}</div>
                </span> */}
              {/* </a> */}
              {/* <ModalVideo show={modalShow} onHide={hideModal} video={heroButton?.url} /> */}
            </>
          ) : (
            <img
              src={image?.fluid?.images?.fallback?.src}
              alt={image.title || ""}
              className={classNames("img-fluid", classes.heroImage)}
            />
          )}
        </div>
      </div>
    </section>
  )
}
